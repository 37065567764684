import { TriggerDefinition } from '../../../definition';

export default {
  name: 'New Contact',
  key: 'new_hubspot_contact',
  description: 'A new contact is created in Hubspot.',
  icon: 'i-respell-hubspot',
  options: {},
  outputs: {
    contact: {
      key: 'contact',
      name: 'Hubspot Contact',
      type: 'object',
      isOptional: false,
      listDepth: 0,
      metadata: {
        schema: {
          reference: {
            namespace: 'hubspot',
            member: 'get_properties_contact_hook',
          },
        },
      },
      value: null,
    },
  },
} satisfies TriggerDefinition;
