import { TriggerDefinition } from '../../../definition';

export default {
  key: 'ashby-candidates',
  name: 'New Canididate',
  description: 'A new canididate is created.',
  icon: 'i-respell-ashby',
  options: {
    job: {
      key: 'job',
      name: 'Job',
      description:
        'Restrict the trigger to a specific job the candidate is applying for.',
      isOptional: true,
      type: 'text/plain',
      listDepth: 0,
      metadata: {
        options: {
          reference: {
            namespace: 'ashby',
            member: 'list_jobs',
          },
        },
      },
      value: null,
    },
  },
  outputs: {
    candidate: {
      metadata: {
        schema: {
          id: {
            key: 'id',
            name: 'ID',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          createdAt: {
            key: 'createdAt',
            name: 'Created At',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          name: {
            key: 'name',
            name: 'Name',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          primaryEmailAddress: {
            key: 'primaryEmailAddress',
            name: 'Primary Email Address',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          position: {
            key: 'position',
            name: 'Position',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          company: {
            key: 'company',
            name: 'Company',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          school: {
            key: 'school',
            name: 'School',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          applicationIds: {
            key: 'applicationIds',
            name: 'Application IDs',
            isOptional: false,
            type: 'text/plain',
            listDepth: 1,
            value: '',
          },
          resumeFileHandle: {
            key: 'resumeFileHandle',
            name: 'Resume File Handle',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          profileUrl: {
            key: 'profileUrl',
            name: 'Profile URL',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
          source: {
            key: 'source',
            name: 'Source',
            isOptional: false,
            type: 'text/plain',
            listDepth: 0,
            value: '',
          },
        },
      },
      type: 'object',
      listDepth: 0,
      isOptional: false,
      value: {},
      name: 'Candidate',
      key: 'candidate',
    },
  },
} satisfies TriggerDefinition;
