<script setup lang="ts">
import type { Variable } from '@respell/utils';
import { fileMap } from '@respell/utils';
import AppCalendar from '~/components/app/AppCalendar.vue';
import AppDropdown from '~/components/app/AppDropdown.vue';
import AppFileInput from '~/components/app/AppFileInput.vue';
import AppFilePond from '~/components/app/AppFilePond.vue';
import AppMultiInput from '~/components/app/AppMultiInput.vue';
import AppObjectInput from '~/components/app/AppObjectInput.vue';
import AppSlider from '~/components/app/AppSlider.vue';
import AppTextEditor from '~/components/app/AppTextEditor.vue';
import AppToggle from '~/components/app/AppToggle.vue';
import CodeEditor from '~/components/editor/config/CodeEditor.vue';
import VariablePicker from '~/components/editor/config/VariablePicker.vue';
import VariableNode from './VariableNode.vue';

const props = defineProps({
  option: {
    type: Object as PropType<Variable>,
    required: true,
  },
  formKey: {
    type: String,
    default: null,
  },
  hasError: {
    type: Boolean,
    default: false,
  },
  isSearching: {
    type: Boolean,
    default: false,
  },
  hasContext: {
    type: Boolean,
    default: false,
  },
  ownerType: {
    type: String,
    default: 'Spell',
  },
  ownerId: {
    type: String,
    default: null,
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'md',
  },
  injectable: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  localOnly: {
    type: Boolean,
    default: false,
  },
});

const modelValue = defineModel<any>();
const searchQuery = defineModel<string>('query', {
  required: false,
  default: '',
});

const { selectedStep } = useSelectedStep();
const { emitFormChange } = useFormGroup();

const formName = computed(() => props.formKey ?? props.option.key);

const isVisible = useConditional(props.option);

const isRange = computed(
  () => props.option.type?.includes('number') && props.option.metadata?.max,
);
const isText = computed(() => {
  return (
    props.option.type?.includes('text') || props.option.type?.includes('number')
  );
});
const showVariablePicker = computed(() => {
  return props.option.metadata?.variableOnly ?? false;
});
const showRangeValue = computed(() => {
  return isRange.value && typeof modelValue.value === 'number';
});
const isDropdown = computed(() => props.option.metadata?.options);
const isMultiple = computed(() =>
  props.readonly
    ? Array.isArray(modelValue.value)
    : props.option.listDepth > 0 && !isDropdown.value,
);
const allowInject = computed(
  () =>
    !props.readonly &&
    !props.option.metadata?.preventInject &&
    props.injectable,
);

const isVariable = computed(() => {
  if (typeof modelValue.value === 'string') {
    const matches = modelValue.value.match(variableRegex);
    return matches !== null && matches.length > 0;
  }
  return false;
});
</script>
<template>
  <div v-if="isVisible" class="w-full">
    <!-- Input Types -->
    <UFormGroup
      eager-validation
      :name="formName"
      :size="size === 'sm' ? 'lg' : 'xl'"
      :description="hideLabel ? undefined : option?.description"
      :hint="showRangeValue ? modelValue.toString() : undefined"
      :required="!option.isOptional"
      :ui="{
        label: {
          base: [
            hasError ? 'text-red-500' : 'text-gray-700',
            'font-bold inline-flex',
          ],
          required: hasError ? 'after:text-red-500' : 'after:text-primary-600',
        },
        wrapper:
          isMultiple && !option.type?.includes('file')
            ? 'border border-gray-300 border-dashed rounded-2xl p-m '
            : undefined,
        inner: 'mb-4',
      }"
    >
      <template v-if="!hideLabel" #label>
        <slot name="label">
          {{ option?.name }}
        </slot>
      </template>

      <AppDropdown
        v-if="isDropdown"
        v-model="modelValue"
        v-model:query="searchQuery"
        :options="option.metadata?.options"
        :searchable="option.metadata?.isSearchable"
        :creatable="option.metadata?.allowsCustom"
        :multiple="option.listDepth > 0"
        :is-searching="isSearching"
        :injectable="allowInject"
        :variable-schema="option.metadata?.schema"
      />

      <div v-else-if="option.type?.includes('file')" class="contents">
        <AppFilePond
          v-if="localOnly"
          v-model="modelValue"
          :accepted-file-types="fileMap[option.type]"
        />
        <AppFileInput
          v-else
          v-model="modelValue"
          :owner-type="ownerType"
          :owner-id="ownerId"
          :allow-multiple="isMultiple"
          :type="option.type"
          :editable="!props.readonly"
          :injectable="allowInject"
          :accepted-file-types="fileMap[option.type]"
        />
      </div>

      <AppMultiInput
        v-else-if="isMultiple"
        v-model="modelValue"
        :option="option"
        :has-context="hasContext"
        :injectable="allowInject"
        :form-key="formKey"
        :size="size"
        :readonly="props.readonly"
      />

      <VariablePicker
        v-else-if="showVariablePicker"
        v-model="modelValue"
        type="variable"
        class="shrink-0"
        @update:model-value="emitFormChange"
      >
        <template #button="{ incoming }">
          <UButton
            trailing-icon="i-ph-caret-down"
            color="white"
            block
            variant="solid"
            size="xl"
            :ui="{ font: 'font-normal', block: 'justify-between' }"
          >
            <VariableNode v-if="isVariable" v-model="modelValue" size="sm" />
            <span v-else class="flex flex-row items-center gap-2 max-w-full">
              <UIcon name="i-ph-diamonds-four" class="w-5 h-5 text-gray-500" />
              <p class="truncate">
                {{ option.metadata?.hint ?? 'Select a variable' }}
              </p>
            </span>
          </UButton>
        </template>
      </VariablePicker>

      <span v-else-if="option.type === 'object'" class="contents">
        <CodeEditor
          v-if="props.readonly"
          :key="`json-${option.key}-${selectedStep?.id}`"
          :model-value="{
            language: 'json',
            content: JSON.stringify(modelValue ?? {}),
          }"
          :languages="['json']"
          :editable="false"
        />

        <VariableNode v-else-if="isVariable" v-model="modelValue" size="sm" />

        <AppObjectInput
          v-else
          v-model="modelValue"
          :option="option"
          :has-context="hasContext"
          :form-key="formKey"
          :injectable="allowInject"
          :size="size"
        />
      </span>

      <AppSlider
        v-else-if="isRange"
        v-model="modelValue"
        :option="option"
        :injectable="allowInject"
        :readonly="props.readonly"
      />

      <AppTextEditor
        v-else-if="isText"
        :key="`text-${option.key}-${selectedStep?.id}`"
        v-model="modelValue"
        :placeholder="option.metadata?.hint"
        :is-multiline="option.metadata?.isMultiline"
        :show-wizard="option.metadata?.isPrompt"
        :type="option.type"
        :injectable="allowInject"
        :readonly="props.readonly"
        :title="option.name"
        :description="option.description"
      />

      <AppToggle
        v-else-if="option.type === 'boolean'"
        v-model="modelValue"
        :injectable="allowInject"
        :readonly="props.readonly"
      />

      <CodeEditor
        v-else-if="option.type === 'code'"
        :key="`code-${option.key}-${selectedStep?.id}`"
        v-model="modelValue"
        :languages="option.metadata?.allowedLanguages"
        :placeholder="option.metadata?.hint"
        :injectable="allowInject"
        :editable="!props.readonly"
      />

      <AppCalendar
        v-else-if="
          option.type === 'date' ||
          option.type === 'datetime' ||
          option.type === 'daterange' ||
          option.type === 'time'
        "
        v-model="modelValue"
        :type="option.type"
        :injectable="allowInject"
        :readonly="props.readonly"
      />
    </UFormGroup>
  </div>
</template>
