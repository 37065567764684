<script setup lang="ts">
import type { Json } from '@respell/utils';

defineProps<{
  searchable?: boolean;
  creatable?: boolean;
  isSearching?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  placeholder?: string;
  searchablePlaceholder?: string;
  valueAttribute: string;
  dropdownOptions: any[] | Json;
  size?: string;
}>();

const selected = defineModel<string | string[] | Json>();
const searchQuery = defineModel<string>('query', {
  required: false,
  default: '',
});
</script>
<template>
  <USelectMenu
    v-model="selected"
    v-model:query="searchQuery"
    :value-attribute="valueAttribute"
    option-attribute="name"
    :search-attributes="['name', valueAttribute]"
    :options="dropdownOptions"
    :size="size ?? 'xl'"
    :searchable="searchable || creatable"
    :disabled="disabled"
    :loading="isSearching"
    :creatable="creatable"
    :multiple="multiple"
    :searchable-placeholder="
      searchQuery || searchQuery === ''
        ? 'Start typing to see more options...'
        : (searchablePlaceholder ?? creatable)
          ? 'Search for options or create one...'
          : 'Search for results...'
    "
    show-create-option-when="always"
    selected-icon="i-ph-x-circle-fill"
    class="grow w-full shrink"
    :popper="{ placement: 'bottom' }"
  >
    <template v-if="dropdownOptions" #label>
      <span class="flex gap-1 max-w-full overflow-scroll justify-start">
        <span v-if="!selected?.length" class="dimmed">{{
          placeholder
            ? placeholder
            : multiple
              ? 'Select one or more options'
              : 'Select an option'
        }}</span>
        <span
          v-for="(option, index) in selected"
          v-else-if="multiple"
          :key="option"
          class="contents"
        >
          <UIcon
            v-if="
              dropdownOptions.find((o) => o[valueAttribute] === option)?.icon
            "
            :name="
              dropdownOptions.find((o) => o[valueAttribute] === option)?.icon
            "
            class="w-4 h-4"
          />
          <p class="truncate">
            {{ dropdownOptions.find((o) => o[valueAttribute] === option)?.name
            }}{{ index + 1 < selected.length ? ',' : '' }}
          </p>
        </span>
        <span v-else class="contents">
          <UIcon
            v-if="
              dropdownOptions.find((o) => o[valueAttribute] === selected)?.icon
            "
            :name="
              dropdownOptions.find((o) => o[valueAttribute] === selected)?.icon
            "
            class="w-4 h-4"
          />
          <p class="truncate">
            {{
              dropdownOptions.find((o) => o[valueAttribute] === selected)?.name
            }}
          </p>
        </span>
      </span>
    </template>
    <template #option-create="{ option: newOption }">
      <span class="text-gray-500">Create:</span> "{{
        newOption.name ?? newOption
      }}"
    </template>
    <template #empty> No options available</template>
  </USelectMenu>
</template>
