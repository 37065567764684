<script setup lang="ts">
import definitions from '@respell/steps';
import type { DataType, Variable } from '@respell/utils';
import AppStepLabel from '~/components/app/AppStepLabel.vue';
import { triggerType } from '~/stores/canvas';

const canvasStore = useCanvasStore();
const tutorialStore = useTutorialStore();

const { tutorialStep } = storeToRefs(tutorialStore);
const { selectedStep } = useSelectedStep();
const modelValue = defineModel<string>();

const props = defineProps<{
  handleClick?: ({ variable, step }: { variable: string; step: any }) => void;
  type: DataType | 'dropdown' | 'lookup' | 'variable';
  isRange?: boolean;
  isActive?: boolean;
}>();

const searchQuery = ref('');

// Keep the open and isReady logic from the master branch
const open = ref(false);
const isReady = computed(() => props.isActive && !open.value);
defineShortcuts({
  '/': {
    whenever: [isReady],
    usingInput: true,
    handler: () => (open.value = !open.value),
  },
});

// TODO: Remove this when Nuxt UI fixes nested popovers
const isNested = inject('isNested');

// Retain the isDate and isText computed properties
const isDate = computed(() =>
  ['date', 'datetime', 'time'].includes(props.type),
);

const isText = computed(() => {
  return (
    (props.type.includes('text') ||
      props.type.includes('number') ||
      props.type === 'code') &&
    !props.isRange
  );
});

const showTabTooltip = computed(
  () => props.type.includes('file') || isDate.value,
);

// Compute incoming steps based on the selected step in the canvas store
const incoming = computed(() => {
  if (!selectedStep.value) return { options: [], steps: [] };

  const inLoop = !!selectedStep.value.parentNode;

  // Fetch all incoming steps, filtering out loop steps if we're not in a loop
  const previousNodes = canvasStore.getPreviousNodes(
    selectedStep.value.id,
    inLoop,
  );

  // Format nodes using step slug and step definition
  const previousSteps = previousNodes.map((node) => ({
    id: node.id,
    slug: node.type === 'review' ? 'review' : node.data.slug,
    label: node.label,
    type: node.type,
    inLoop: !!node.parentNode,
    ...(node.type === 'trigger' ? triggerType : definitions[node.data.key]),
  }));

  // Filter steps to include only those with appropraite outputs
  const valid = previousSteps
    .map((step) => {
      // Fetch outputs for the step
      const outputs = canvasStore.fetchStepOutputs(step, step.id, inLoop);
      // Filter outputs based on type and search query

      // TODO: Add back variable filtering
      // if (typeMap[props.type].accepts.includes(variable.type)) {
      //   return true;
      // }
      // if (variable.metadata?.schema) {
      //   const { schema } = variable.metadata;
      //   return (
      //     Object.values(schema).some((item) =>
      //       typeMap[props.type].accepts.includes(item.type),
      //     ) || 'reference' in schema
      //   );
      // }

      const filteredOutputs = outputs
        ? Object.values(outputs as JSON).filter((variable: Variable) =>
            variable.name
              .toLowerCase()
              .includes(searchQuery.value?.toLowerCase()),
          )
        : [];
      // Format as Accordion Item
      return {
        slug: step.slug,
        key: step.key,
        icon: step.icon,
        label: step.label,
        name: step.name,
        inLoop: step.inLoop,
        outputs: filteredOutputs,
        // Styling for accordion
        variant: 'solid',
        color: 'gray',
        size: 'lg',
      };
    })
    .filter((step) => step.outputs?.length);
  // TODO: Design and download global variable svg
  valid.push({
    slug: '_internal_variables',
    key: '_internal_variables',
    icon: 'i-ph-gear-fill',
    label: 'Global Variables',
    name: '',
    inLoop: false,
    outputs: [
      {
        key: 'name',
        name: 'Spell Name',
        type: 'text/plain',
        listDepth: 0,
        isOptional: false,
        value: null,
      },
      {
        key: 'version',
        name: 'Spell Version',
        type: 'text/plain',
        listDepth: 0,
        isOptional: false,
        value: null,
      },
      {
        key: 'currentTime',
        name: 'Current Time',
        value: null,
        type: 'datetime',
        listDepth: 0,
        isOptional: false,
      },
      {
        key: 'createdAt',
        name: 'Created At',
        value: null,
        type: 'datetime',
        listDepth: 0,
        isOptional: false,
      },
    ],
    // Styling for accordion
    variant: 'solid',
    color: 'gray',
    size: 'lg',
  });
  return { valid, all: previousSteps };
});

// Dynamically generate tabs based on the type prop
const tabs = computed(() => {
  const baseTabs = [];

  const selectTab = {
    slot: 'select',
    label: 'Select',
    icon: 'i-ph-check-circle',
    disabled: false,
  };

  if (props.type === 'lookup') {
    baseTabs.push(
      {
        slot: 'lookup',
        label: 'Lookup',
        icon: 'i-ph-magnifying-glass',
        disabled: false,
      },
      selectTab,
    );
  } else {
    if (props.type === 'dropdown') {
      baseTabs.push(selectTab);
    }
    baseTabs.push({
      slot: 'variable',
      label: 'Variable',
      icon: 'i-ph-diamonds-four',
      disabled: !incoming.value.valid?.length,
    });
  }

  if (props.type.includes('file')) {
    baseTabs.push({
      slot: 'file',
      label: 'Upload',
      icon: 'i-ph-cloud-arrow-up',
      disabled: false,
    });
  } else if (['date', 'datetime', 'time'].includes(props.type)) {
    baseTabs.push({
      slot: 'calendar',
      label: 'Calendar',
      icon: 'i-ph-calendar-dots',
      disabled: false,
    });
  }

  return baseTabs;
});

const injectVariable = ({ variable, step }) => {
  if (props.handleClick) {
    props.handleClick({ variable, step });
  } else {
    modelValue.value = `{{${step.slug}.${variable.key}}}`;
  }
};

const selectedTab = ref(isDate.value ? 1 : 0);
</script>

<template>
  <UPopover
    v-model:open="open"
    class="flex shrink-0"
    :popper="{
      placement: isText ? 'auto-start' : 'bottom',
      strategy: 'fixed',
      adaptive: true,
    }"
    :ui="{
      wrapper: isNested ? 'fixed z-50' : '',
      base: 'overflow-visible',
    }"
  >
    <UTooltip
      :prevent="showTabTooltip"
      :text="
        !incoming.all?.length
          ? 'Connect step to use variables'
          : !incoming.valid?.length
            ? 'No incoming variables available'
            : 'Use variable'
      "
      :shortcuts="['/']"
      class="w-full"
    >
      <slot name="button" :incoming="incoming">
        <UButton
          size="md"
          variant="ghost"
          :padded="false"
          :disabled="!incoming.valid?.length"
          :color="type === 'code' ? 'gray' : 'primary'"
          icon="i-ph-plus-square-duotone"
          :class="{ radiate: tutorialStep === 4 }"
          :ui="{
            color: { gray: { ghost: 'text-gray-400' } },
            icon: {
              size: {
                md: 'h-6 w-6',
              },
            },
          }"
        />
      </slot>
    </UTooltip>
    <template #panel>
      <UTabs
        v-model="selectedTab"
        :items="tabs"
        :ui="{
          wrapper: 'p-1',
          container: 'w-96 overflow-visible',
          list: {
            base: 'flex flex-row justify-start',
            background: 'bg-white',
            width: 'w-full',
            height: 'h-fit',
            padding: '!py-0',
            rounded: 'rounded-none',
            marker: {
              base: 'hidden',
            },
            tab: {
              base: 'w-fit',
              padding: 'px-4',
              height: 'h-10',
              active: 'border-b-2 border-primary-500',
              inactive: 'border-b border-gray-300',
              rounded: 'rounded-none',
              icon: 'w-5 h-5',
            },
          },
        }"
      >
        <template #default="{ item, index, selected }">
          <UTooltip
            :key="item.slot"
            :prevent="!item.disabled"
            :text="
              !incoming.all?.length
                ? 'Connect step to use variables'
                : !incoming.valid?.length
                  ? 'No incoming variables available'
                  : 'Use variable'
            "
          >
            <p class="truncate subtitle">{{ item.label }}</p>
          </UTooltip>
        </template>
        <template #lookup="{ item }">
          <slot name="lookup" />
        </template>
        <template #select="{ item }">
          <slot name="select" />
        </template>
        <template #variable="{ item }">
          <div
            class="flex flex-col px-2 mb-4 items-start pt-2 gap-2 cursor-pointer max-h-60 overflow-scroll"
          >
            <UFormGroup name="search" class="w-full sticky top-0 z-50 bg-white">
              <UInput
                v-model="searchQuery"
                icon="i-ph-magnifying-glass-bold"
                color="white"
                variant="outline"
                class="w-full"
                size="lg"
                placeholder="Search for incoming variables"
                autofocus
              />
            </UFormGroup>

            <UAccordion
              v-for="(step, index) in incoming.valid"
              :key="index"
              :items="[step]"
              class="w-full border border-gray-200 px-s rounded-md"
            >
              <template #default="{ item, open }">
                <div class="flex flex-row py-s justify-start w-full gap-2">
                  <UIcon
                    :name="item.icon"
                    class="rounded-full border-gray-50 text-xl outline outline-gray-200 border shrink-0"
                  />
                  <AppStepLabel
                    size="sm"
                    :label="item.label"
                    :step-name="item.name"
                    class="truncate"
                  />
                  <UBadge
                    v-if="item.inLoop"
                    size="xs"
                    color="gray"
                    label="Loop Step"
                    class="shrink-0"
                  />
                  <UIcon
                    name="i-ph-caret-right"
                    class="text-xl ml-auto transform transition-transform duration-200"
                    :class="[open && 'rotate-90']"
                  />
                </div>
              </template>
              <template #item="{ item }">
                <div
                  class="flex flex-row cursor-default flex-wrap gap-2 w-full justify-start"
                >
                  <AppVariableTag
                    v-for="(variable, idx) in item.outputs"
                    :key="idx"
                    :variable="variable"
                    size="sm"
                    :on-click="() => injectVariable({ variable, step: item })"
                  />
                </div>
              </template>
            </UAccordion>
          </div>
        </template>
        <template #file="{ item }">
          <slot name="file" />
        </template>
        <template #calendar="{ item }">
          <slot name="calendar" />
        </template>
      </UTabs>
    </template>
  </UPopover>
</template>
