import { StepDefinition } from '../../types';

export default {
  key: 'create_contact_hubspot',
  name: 'Create Contact',
  description: 'Creates a Hubspot Contact',
  icon: 'i-respell-hubspot',
  category: 'integrations',
  services: ['hubspot'],
  options: {
    account: {
      key: 'account',
      name: 'Account',
      description: 'The account to associate the contact with.',
      type: 'text/plain',
      metadata: {
        options: {
          reference: {
            namespace: 'hubspot',
            member: 'get_companies',
          },
        },
      },
      isOptional: false,
      listDepth: 0,
      value: null,
    },
    contact: {
      key: 'contact',
      name: 'Contact',
      type: 'object',
      metadata: {
        schema: {
          reference: {
            namespace: 'hubspot',
            member: 'get_properties_contact',
          },
        },
      },
      isOptional: false,
      listDepth: 0,
      value: null,
    },
  },
  outputs: {},
} satisfies StepDefinition;
