import revive_payload_client_BBeWOozco8 from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_jkgc3pifwkmp6p3jeluxktv3hq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_2CkgSIhAye from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_jkgc3pifwkmp6p3jeluxktv3hq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ftltSa0rNc from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_jkgc3pifwkmp6p3jeluxktv3hq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_UpnF1s4GA3 from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.14.2_vite@5.2.2_@types+node@20.12.7_sass@1.75_rcem44y2co3dnpkman57u2iht4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import posthog_client_SeidNRGOxv from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt-posthog@1.5.3_magicast@0.3.5_rollup@4.14.2_webpack-sources@3.2.3/node_modules/nuxt-posthog/dist/runtime/plugins/posthog.client.js";
import payload_client_m9SLzwS0g5 from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_jkgc3pifwkmp6p3jeluxktv3hq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_g743zWQydN from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_jkgc3pifwkmp6p3jeluxktv3hq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_iFptFGwtho from "/home/runner/work/respell/respell/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.14.2_typescript@5.4.3_vue@3.4.21_typescript@5.4.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/respell/respell/apps/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_LEZsH8vZ9x from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_jkgc3pifwkmp6p3jeluxktv3hq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_W3OZFpXx37 from "/home/runner/work/respell/respell/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.11.1_magicast@0.3.5_rollup@4.14.2_vite@5.2.2_@types+node@20.12.7_oszrmc2bybgehgdttclcyuvyii/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_vFRLU3Zjfv from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.14.2_vite@5.2.2_@types+node@20.12.7_sass@1_hvwo744a6wzpoxjpkjx3e6yuei/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_eNwkptTSbT from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.14.2_vite@5.2.2_@types+node@20.12.7_sass@1_hvwo744a6wzpoxjpkjx3e6yuei/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import directives_re6B3JsmYk from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt-posthog@1.5.3_magicast@0.3.5_rollup@4.14.2_webpack-sources@3.2.3/node_modules/nuxt-posthog/dist/runtime/plugins/directives.js";
import plugin_dGWDewQ5j9 from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt-csurf@1.6.1_magicast@0.3.5_rollup@4.14.2_webpack-sources@3.2.3/node_modules/nuxt-csurf/dist/runtime/plugin.mjs";
import slideovers_EfR7dH2m1l from "/home/runner/work/respell/respell/node_modules/.pnpm/@nuxt+ui@2.18.2_axios@1.6.5_change-case@5.4.4_focus-trap@7.5.4_magicast@0.3.5_rollup@4.14.2_s_jnggttouybl5rqzugw3fpwtwrm/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_9j108XmEWp from "/home/runner/work/respell/respell/node_modules/.pnpm/@nuxt+ui@2.18.2_axios@1.6.5_change-case@5.4.4_focus-trap@7.5.4_magicast@0.3.5_rollup@4.14.2_s_jnggttouybl5rqzugw3fpwtwrm/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_RBNvtRvOYI from "/home/runner/work/respell/respell/node_modules/.pnpm/@nuxt+ui@2.18.2_axios@1.6.5_change-case@5.4.4_focus-trap@7.5.4_magicast@0.3.5_rollup@4.14.2_s_jnggttouybl5rqzugw3fpwtwrm/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_AcDN5Sh7Gi from "/home/runner/work/respell/respell/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.5_rollup@4.14.2_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_edaqOvv2gE from "/home/runner/work/respell/respell/node_modules/.pnpm/@nuxt+icon@1.3.1_magicast@0.3.5_rollup@4.14.2_vite@5.2.2_@types+node@20.12.7_sass@1.75.0_ters_nq2phd6xy5eq6zsbh263odgsmu/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_GphxbNiZts from "/home/runner/work/respell/respell/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_@azure+storage-blob@12.17.0_encoding@0.1.13__encoding@0.1.13_next-a_gr5twc62k3e2wtgi6mwlrt6rvq/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_client_qpwKTUfgia from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt-gtag@0.5.7_rollup@4.14.2/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import chunk_reload_client_HG4qZBRlBd from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_jkgc3pifwkmp6p3jeluxktv3hq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1BdlnigI0n from "/home/runner/work/respell/respell/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_rollup@4.14.2_typescript@5.4.3_vue@_3s4eqfugtrvrqugtjirs3wfega/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import filepond_3qnHRCNugt from "/home/runner/work/respell/respell/apps/nuxt/src/plugins/filepond.ts";
import loadGoogleAuthScripts_OqUG037DcI from "/home/runner/work/respell/respell/apps/nuxt/src/plugins/loadGoogleAuthScripts.ts";
import authUnthread_client_wQjIaLV3NI from "/home/runner/work/respell/respell/apps/nuxt/src/plugins/authUnthread.client.ts";
import casl_D1YIVgqED7 from "/home/runner/work/respell/respell/apps/nuxt/src/plugins/casl.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/respell/respell/apps/nuxt/src/plugins/sentry.client.ts";
import defaults_8VCzb04ZWA from "/home/runner/work/respell/respell/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.11.1_magicast@0.3.5_rollup@4.14.2_vite@5.2.2_@types+node@20.12.7_oszrmc2bybgehgdttclcyuvyii/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_BBeWOozco8,
  unhead_2CkgSIhAye,
  router_ftltSa0rNc,
  _0_siteConfig_UpnF1s4GA3,
  posthog_client_SeidNRGOxv,
  payload_client_m9SLzwS0g5,
  check_outdated_build_client_g743zWQydN,
  plugin_vue3_iFptFGwtho,
  components_plugin_KR1HBZs4kY,
  prefetch_client_LEZsH8vZ9x,
  titles_W3OZFpXx37,
  siteConfig_vFRLU3Zjfv,
  inferSeoMetaPlugin_eNwkptTSbT,
  directives_re6B3JsmYk,
  plugin_dGWDewQ5j9,
  slideovers_EfR7dH2m1l,
  modals_9j108XmEWp,
  colors_RBNvtRvOYI,
  plugin_client_AcDN5Sh7Gi,
  plugin_edaqOvv2gE,
  plugin_GphxbNiZts,
  plugin_client_qpwKTUfgia,
  chunk_reload_client_HG4qZBRlBd,
  plugin_1BdlnigI0n,
  filepond_3qnHRCNugt,
  loadGoogleAuthScripts_OqUG037DcI,
  authUnthread_client_wQjIaLV3NI,
  casl_D1YIVgqED7,
  sentry_client_KAXFuL2wum,
  defaults_8VCzb04ZWA
]