<script setup lang="ts">
import { subject } from '@casl/ability';
import type { SubscriptionType } from '@respell/database';
import { subscriptionTypeToFileSizeLimits } from '@respell/utils';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond/dist/filepond.min.css';
import createVueFilePond from 'vue-filepond';
import { useAppAbility } from '~/composables/useAppAbility';

const { can } = useAppAbility();

const workspaceStore = useWorkspaceStore();
const { workspace, subscriptionType } = storeToRefs(workspaceStore);

const FilePond = createVueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginGetFile,
  FilePondPluginFileValidateSize,
);

const { allowMultiple, type } = defineProps({
  editable: {
    type: Boolean,
    required: true,
  },
  allowMultiple: {
    type: Boolean,
    required: true,
  },
  server: {
    type: Object,
    required: true,
  },
  files: {
    type: Array,
    required: true,
  },
  acceptedFileTypes: {
    type: Array as PropType<string[]> | undefined,
    default: undefined,
  },
  type: {
    type: String,
    default: 'file/document',
  },
  beforeRemoveFile: {
    type: Function,
    required: false,
  },
});

const maxFileSize = computed(
  () =>
    subscriptionTypeToFileSizeLimits[
      subscriptionType.value as SubscriptionType
    ][type],
);

const labelIdle = computed(
  () =>
    `Drop ${allowMultiple ? 'your files' : 'a file'} or <span class='filepond--label-action'>Browse</span>`,
);
</script>
<template>
  <client-only>
    <UTooltip
      class="w-full"
      :prevent="!editable || can('editFiles', subject('Workspace', workspace))"
      text="Uploads disabled. Contact your workspace admin."
    >
      <div id="app" class="w-full">
        <FilePond
          :disabled="
            !editable || !can('editFiles', subject('Workspace', workspace))
          "
          :allow-drop="editable"
          :allow-browse="editable"
          :allow-paste="editable"
          :allow-replace="editable"
          :allow-revert="editable"
          :allow-remove="editable"
          :allow-process="editable"
          min-file-size="5B"
          :max-file-size="maxFileSize"
          credits="false"
          :label-idle="labelIdle"
          :allow-multiple="allowMultiple"
          :files="files"
          :server="server"
          :accepted-file-types="acceptedFileTypes"
          :before-remove-file="beforeRemoveFile"
        />
      </div>
    </UTooltip>
  </client-only>
</template>
